//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isBoss: this.$store.state.currentAdmin.isBoss,
      items: [{
        icon: "el-icon-s-home",
        index: "/main",
        title: "系统首页",
        isBoss: true
      }, {
        icon: "el-icon-s-tools",
        index: "/webEdit",
        title: "网站设置",
        isBoss: true
      }, {
        icon: "el-icon-user-solid",
        index: "/userList",
        title: "用户管理",
        isBoss: true
      }, {
        icon: "el-icon-postcard",
        index: "/postList",
        title: "文章管理",
        isBoss: false
      }, {
        icon: "el-icon-notebook-2",
        index: "/sortList",
        title: "分类管理",
        isBoss: true
      }, {
        icon: "el-icon-edit-outline",
        index: "/commentList",
        title: "评论管理",
        isBoss: false
      }, {
        icon: "el-icon-s-comment",
        index: "/treeHoleList",
        title: "留言管理",
        isBoss: true
      }, {
        icon: "el-icon-paperclip",
        index: "/resourceList",
        title: "资源管理",
        isBoss: true
      }, {
        icon: "el-icon-bank-card",
        index: "/resourcePathList",
        title: "资源路径管理",
        isBoss: true
      }, {
        icon: "el-icon-sugar",
        index: "/loveList",
        title: "表白墙管理",
        isBoss: true
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};