//
//
//
//
//
//
//
//
//
//
//
//
import myHeader from "./common/myHeader.vue";
import sidebar from "./common/sidebar.vue";
export default {
  components: {
    myHeader: myHeader,
    sidebar: sidebar
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};