//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    logout: function logout() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/user/logout", {}, true).then(function (res) {}).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
      this.$store.commit("loadCurrentAdmin", {});
      localStorage.removeItem("adminToken");
      this.$router.push({
        path: '/'
      });
    }
  }
};